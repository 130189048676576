import { useEffect, useMemo } from "react";
import { Form, Select } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { filter, map } from "lodash";

import { useAreaStore } from "../../stores/useAreaStore";
import { useAreasList } from "../../hooks/apis/vending";

type AreaFormProps = {
  form: FormInstance;
};

export function AreaForm(props: AreaFormProps) {
  const { data: { areas = [] } = {} } = useAreasList();
  const areaStore = useAreaStore();

  useEffect(() => {
    props.form.resetFields();
  }, [props.form]);

  const areaOptions = useMemo(() => {
    const options = map(areas, (item: { name: string; id: string }) => ({
      label: item.name,
      value: item.id,
    }));

    if (areaStore.selectedArea) {
      return filter(options, item => item.value !== areaStore.selectedArea);
    }

    return options;
  }, [areas, areaStore.selectedArea]);

  return (
    <Form form={props.form} autoComplete="off">
      <Form.Item name="targetAreaId" label="目的地場域" rules={[{ required: true, message: "請選擇目的地場域" }]}>
        <Select options={areaOptions} />
      </Form.Item>

      <div className="text-red-400">複製商品需要一些時間，請耐心等待並請不要關閉瀏覽器。</div>
    </Form>
  );
}
