import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Link, Navigate, Route, Routes, useLocation, useSearchParams } from "react-router-dom";
import { first, map } from "lodash";
import axios from "axios";
import { MenuProps } from "rc-menu";
import { Avatar, Dropdown, Form, Layout, Menu, Select, Space } from "antd";
import { PictureOutlined, ShopOutlined, RobotOutlined, PartitionOutlined, LogoutOutlined } from "@ant-design/icons";
import { Content, Header } from "antd/es/layout/layout";
import { useForm } from "antd/es/form/Form";

import "./App.css";

import { PrivateRoute, PublicRoute } from "./route";
import { useAuthStore } from "./stores/useAuthStore";
import { useAreaStore } from "./stores/useAreaStore";

import { useAreasList } from "./hooks/apis/vending";

import { Login } from "./pages/login/Login";
import { MaterialList } from "./pages/material/MaterialList";
import { ProductList } from "./pages/product/ProductList";
import { CreateProduct } from "./pages/product/CreateProduct";
import { MachineList } from "./pages/machine/MachineList";
import { RestockSettingMachine } from "./pages/machine/RestockSettingMachine";
import { CreateGridMenu } from "./pages/machine/CreateGridMenu";
import { AreaList } from "./pages/area/AreaList";

const { Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label: children ? (
      label
    ) : (
      <Link to={key.toString()} key={key}>
        {label}
      </Link>
    ),
    type,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem("素材管理", "/material/", <PictureOutlined />, [getItem("素材列表", "/material")]),
  getItem("商品管理", "/product/", <ShopOutlined />, [
    getItem("商品列表", "/product"),
    getItem("新增商品", "/product/create"),
  ]),
  getItem("機台管理", "/machine/", <RobotOutlined />, [
    getItem("機台列表", "/machine"),
    getItem("機台設置", "/machine/restock-setting"),
  ]),
  getItem("場域管理", "/area/", <PartitionOutlined />, [getItem("場域列表", "/area")]),
];

const App: React.FC = () => {
  const location = useLocation();
  const auth = useAuthStore();
  const areaStore = useAreaStore();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [collapsed, setCollapsed] = useState(true);
  const [form] = useForm<{
    areaId: string;
    companyId: string;
    name: string;
    supplierId: string;
    token: string;
  }>();

  const { data: { areas = [] } = {} } = useAreasList();

  useLayoutEffect(() => {
    if (token && !auth.token) {
      auth.login(token);
    }
  }, [token, auth.login, auth, auth.token]);

  useLayoutEffect(() => {
    if (auth.token) {
      axios.interceptors.request.clear();
      axios.interceptors.request.use(config => {
        config.headers.Authorization = `Bearer ${auth.token}`;

        return config;
      });
    }
  }, [auth.token]);

  useLayoutEffect(() => {
    axios.interceptors.response.clear();

    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response?.status === 401) {
          auth.logout();
        }

        throw error;
      }
    );
  }, [auth, auth.logout]);

  const areaId = Form.useWatch("areaId", form);

  useEffect(() => {
    form.resetFields();
  }, [form]);

  useLayoutEffect(() => {
    if (areaId && areaStore.selectedArea !== areaId) {
      areaStore.setArea(areaId);
    }
  }, [areaStore, areaId]);

  const areaOptions = useMemo(
    () =>
      map(areas, (item: { name: string; id: string }) => ({
        label: item.name,
        value: item.id,
      })),
    [areas]
  );

  return (
    <Layout className="min-h-[100vh] max-h-[100vh] h-[100vh]">
      {auth.token && (
        <Sider collapsible collapsed={collapsed} onCollapse={value => setCollapsed(value)}>
          <Menu theme="dark" mode="inline" items={items} selectedKeys={[location.pathname]} />
        </Sider>
      )}
      <Layout>
        {auth.token && (
          <Header className="flex justify-between items-center px-4">
            <span className="flex items-center text-2xl font-bold text-gray-200">
              主控台
              <AreaSelector form={form} areaOptions={areaOptions} />
            </span>

            <Dropdown
              menu={{
                items: [
                  {
                    key: "logout",
                    icon: <LogoutOutlined />,
                    label: "登出",
                    onClick: auth.logout,
                  },
                ],
              }}
            >
              <Space>
                <Avatar size="large" src={auth.user?.photoUrl} className="bg-gray-200 text-[#001529]">
                  {first(auth.user?.displayName?.split(""))}
                </Avatar>
                <span className="text-gray-200">{auth.user?.displayName}</span>
              </Space>
            </Dropdown>
          </Header>
        )}
        <Content className="p-4 overflow-auto">
          <Routes>
            <Route element={<PrivateRoute />}>
              <Route
                path="material"
                children={[
                  <Route index key="index" element={<MaterialList />} />,
                  <Route path="*" key="default" element={<Navigate to="" replace />} />,
                ]}
              />
              <Route
                path="product"
                children={[
                  <Route index key="index" element={<ProductList />} />,
                  <Route key="create" path="create" element={<CreateProduct />} />,
                  <Route path="*" key="default" element={<Navigate to="" replace />} />,
                ]}
              />
              <Route
                path="machine"
                children={[
                  <Route index key="index" element={<MachineList />} />,
                  <Route key="restock-setting" path="restock-setting" element={<RestockSettingMachine />} />,
                  <Route key="menu-create" path="menu-create" element={<CreateGridMenu />} />,
                  <Route path="*" key="default" element={<Navigate to="" replace />} />,
                ]}
              />
              <Route
                path="area"
                children={[
                  <Route index key="index" element={<AreaList />} />,
                  <Route path="*" key="default" element={<Navigate to="" replace />} />,
                ]}
              />
              <Route path="*" element={<Navigate to="material" replace />} />
            </Route>
            <Route element={<PublicRoute />}>
              <Route path="login" element={<Login />} />
            </Route>
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};

export default App;

const AreaSelector = React.memo((props: { form: any; areaOptions: Array<{ label: string; value: string }> }) => {
  return (
    (props.areaOptions?.length > 0 && (
      <Form form={props.form} autoComplete="off" initialValues={{ areaId: props.areaOptions[0]?.value }}>
        <Form.Item className="ml-5 mb-0" name="areaId" rules={[{ required: true, message: "請選擇場域" }]}>
          <Select placeholder="請選擇場域" options={props.areaOptions} />
        </Form.Item>
      </Form>
    )) ||
    null
  );
});
