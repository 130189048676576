import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Card, Checkbox, Divider, Flex, Image, InputNumber, Space, Tag, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import { cloneDeep, filter, forEach, groupBy, isEmpty, keys, map, reduce, sortBy } from "lodash";
import { ClockCircleOutlined } from "@ant-design/icons";
import numeral from "numeral";
import dayjs from "dayjs";

import { FALLBACK_IMAGE_PATH, NULLABLE_IMAGE_PATH } from "../../constants/material";
import { useMachineRestockSetting } from "../../hooks/apis/vending";

import { AmountForm } from "./AmountForm";
import { ProductForm } from "./ProductForm";

type ProductItem = {
  productCode: string;
  productName: string;
  brandName: string;
  productType: string;
  productInfo: string;
  productMemo: string;
  price: number;
  expireDate: number | null;
  fileName: string;
  photo: string;
};

type FieldType = {
  id: string;
  code: string;
  name: string;
};

type MachineCodeOptions = Array<{
  label: string;
  value: string;
}>;

type LayerItem = {
  layer: string;
  max: number;
  min: number;
  productCode: string;
  productId: number;
};

export function RestockSettingAisleForm(props: {
  data: FieldType;
  modal: any;
  machineCodeOptions?: MachineCodeOptions;
  updateRestockSetting: any;
  productsMapping: {
    [productCode: string]: ProductItem;
  };
  areaId: string;
}) {
  const [checkedList, setCheckedList] = useState<{
    machineId: string;
    data: {
      [layer: string]: LayerItem;
    };
  }>({ machineId: "", data: {} });
  const [shelfCount, setShelfCount] = useState<{ [layer: string]: number }>({});

  const [amountForm] = useForm<{
    operator: boolean;
    max: number;
  }>();
  const [productForm] = useForm<{
    productCode: string;
  }>();

  const { data: { settings = [] } = {}, isLoading: isMachinesLoading } = useMachineRestockSetting({
    machineId: props.data.id,
  });

  const groupingSettings = useMemo(() => {
    if (settings.length) {
      let sortedSettings = sortBy(settings, "layer");

      return groupBy(sortedSettings, item => item.layer.substr(0, 1));
    }
    return {};
  }, [settings]);

  const onClickAllCheckbox = useCallback(
    (allLayer: LayerItem[], nextStatus = false) => {
      setCheckedList(checkedList => {
        const newCheckedList = cloneDeep(checkedList);
        newCheckedList.machineId = props.data.id;

        forEach(allLayer, item => {
          if (item.layer) {
            if (nextStatus) {
              newCheckedList.data[item.layer] = item;
            } else {
              delete newCheckedList.data[item.layer];
            }
          }
        });

        return newCheckedList;
      });
    },
    [props.data.id]
  );

  const onClickCheckBox = useCallback(
    (checkedTarget: { layer: string; max: number; min: number; productCode: string; productId: number }) => {
      setCheckedList(checkedList => {
        const newCheckedList = cloneDeep(checkedList);
        newCheckedList.machineId = props.data.id;

        if (isEmpty(newCheckedList.data[checkedTarget.layer])) {
          newCheckedList.data[checkedTarget.layer] = checkedTarget;
        } else {
          delete newCheckedList.data[checkedTarget.layer];
        }

        return newCheckedList;
      });
    },
    [props.data.id]
  );

  useEffect(() => {
    if (settings.length > 0) {
      setShelfCount(
        reduce(
          settings,
          (accumulate: { [layer: string]: number }, item) => {
            accumulate[item.layer] = item.max;
            return accumulate;
          },
          {}
        )
      );
    }
  }, [settings]);

  return (
    <>
      <Space direction="horizontal" className="py-2 flex">
        <Button
          type="primary"
          disabled={!keys(checkedList.data).length}
          onClick={() => {
            props.modal.confirm({
              cancelText: "取消",
              content: <AmountForm form={amountForm} />,
              icon: null,
              okText: "確認",
              title: "批次調整數量",
              onOk: async () => {
                const values = await amountForm.validateFields();

                const thresholdValue = (values.operator ? 1 : -1) * values.max;
                let newValue = 1;

                await props.updateRestockSetting({
                  machineId: checkedList.machineId,
                  settings: map(checkedList.data, (item: LayerItem) => {
                    newValue = item.max + thresholdValue;
                    if (newValue < item.min) {
                      newValue = item.min;
                    } else if (newValue < 1) {
                      newValue = 1;
                    }

                    return {
                      ...item,
                      max: newValue,
                    };
                  }),
                });

                setCheckedList(checkedList => ({
                  ...checkedList,
                  data: {},
                }));
              },
            });
          }}
        >
          補貨
        </Button>

        {/* <Button
          type="primary"
          disabled={!keys(checkedList.data).length}
          onClick={async () => {
            await props.updateRestockSetting({
              machineId: checkedList.machineId,
              settings: map(
                checkedList.data,
                (item: { layer: string; max: number; min: number; productCode: string; productId: number }) => ({
                  ...item,
                  max: item.min > 0 ? item.min : 0,
                })
              ),
            });

            setCheckedList(checkedList => ({
              ...checkedList,
              data: {},
            }));
          }}
        >
          歸零
        </Button> */}

        <Button
          type="primary"
          disabled={!keys(checkedList.data).length}
          onClick={() => {
            props.modal.confirm({
              cancelText: "取消",
              content: <ProductForm form={productForm} productsMapping={props.productsMapping} areaId={props.areaId} />,
              icon: null,
              okText: "確認",
              title: "換貨/上架",
              onOk: async () => {
                const values = await productForm.validateFields();

                await props.updateRestockSetting({
                  machineId: checkedList.machineId,
                  settings: map(
                    checkedList.data,
                    ({
                      productId,
                      ...item
                    }: {
                      layer: string;
                      max: number;
                      min: number;
                      productCode: string;
                      productId: number;
                    }) => {
                      return {
                        ...item,
                        productCode: values.productCode,
                        min: !item.productCode ? 1 : item.min < 1 ? 1 : item.min,
                        max: !item.productCode ? 1 : item.max,
                      };
                    }
                  ),
                });

                setCheckedList(checkedList => ({
                  ...checkedList,
                  data: {},
                }));
              },
            });
          }}
        >
          換貨/上架
        </Button>

        <Button
          type="primary"
          disabled={!keys(checkedList.data).length}
          onClick={async () => {
            const values = filter(checkedList.data, (item: { productCode: string }) => !!item.productCode);

            if (values.length) {
              await props.updateRestockSetting({
                machineId: checkedList.machineId,
                settings: map(
                  values,
                  (item: { layer: string; max: number; min: number; productCode: string; productId: number }) => {
                    return {
                      ...item,
                      productCode: "",
                    };
                  }
                ),
              });
            }

            setCheckedList(checkedList => ({
              ...checkedList,
              data: {},
            }));
          }}
        >
          下架
        </Button>
      </Space>

      <Card loading={isMachinesLoading}>
        {map(keys(groupingSettings), (groupingKey, index) => {
          return (
            <div key={groupingKey} className="flex flex-wrap">
              {index !== 0 && <Divider type="horizontal" style={{ borderWidth: 5, borderColor: "#000" }} />}

              <div className="flex gap-2 mb-2" style={{ width: "100%" }}>
                <Button
                  type="default"
                  onClick={() => {
                    onClickAllCheckbox(groupingSettings[groupingKey], true);
                  }}
                >
                  全選
                </Button>
                <Button
                  type="default"
                  onClick={() => {
                    onClickAllCheckbox(groupingSettings[groupingKey], false);
                  }}
                >
                  全不選
                </Button>
              </div>
              {map(
                groupingSettings[groupingKey],
                (item: { layer: string; max: number; min: number; productCode: string; productId: number }) => {
                  if (/^A(.*)[5-8]$/.test(item.layer)) {
                    // TODO: 前端先寫死
                    return null;
                  }
                  return (
                    <Card.Grid
                      key={item.layer}
                      className="flex flex-col"
                      style={{ position: "relative", width: 135, padding: 15 }}
                    >
                      <Checkbox
                        className="my-1"
                        checked={checkedList.data && item.layer ? !isEmpty(checkedList.data[item.layer]) : false}
                        onChange={() => onClickCheckBox(item)}
                      >
                        {item.layer}
                      </Checkbox>

                      <Image
                        width={105}
                        height={105}
                        src={`${process.env.REACT_APP_API_SERVER_URL}/vending/areas/${props.areaId}/images/${props.productsMapping[item.productCode]?.fileName}`}
                        fallback={item.productCode ? FALLBACK_IMAGE_PATH : NULLABLE_IMAGE_PATH}
                      />

                      {!!item.productCode && (
                        <Flex vertical gap={3}>
                          <Typography.Text>{props.productsMapping[item.productCode]?.productType}</Typography.Text>

                          <Typography.Text>{props.productsMapping[item.productCode]?.productName}</Typography.Text>

                          <Typography.Text style={{ textAlign: "right", color: "#f00" }}>
                            {numeral(props.productsMapping[item.productCode]?.price).format("$0,0")}
                          </Typography.Text>

                          <InputNumber
                            addonBefore="數量"
                            size="small"
                            min={item.min || 1}
                            value={shelfCount[item.layer] ?? 0}
                            onChange={value =>
                              setShelfCount(shelfCount => {
                                const newShelfCount = cloneDeep(shelfCount);
                                newShelfCount[item.layer] = Number(value);
                                return newShelfCount;
                              })
                            }
                            onBlur={() => {
                              props.updateRestockSetting({
                                machineId: props.data.id,
                                settings: [
                                  {
                                    ...item,
                                    max: shelfCount[item.layer],
                                  },
                                ],
                              });
                            }}
                          />

                          {props.productsMapping[item.productCode]?.expireDate && (
                            <Tag icon={<ClockCircleOutlined />} color="warning">
                              {dayjs(props.productsMapping[item.productCode]?.expireDate)?.format(`YYYY-MM-DD`)}
                            </Tag>
                          )}
                        </Flex>
                      )}
                    </Card.Grid>
                  );
                }
              )}
            </div>
          );
        })}
      </Card>
    </>
  );
}
