import { useEffect } from "react";
import { Form, Select } from "antd";
import { FormInstance } from "antd/es/form/Form";
import Input from "antd/es/input/Input";
import { isEmpty } from "lodash";

import { MACHINE_MODE_OPTIONS } from "../../constants/machine";

type FieldType = {
  id: string;
  code: string;
  name: string;
};

type MachineCodeOptions = Array<{
  label: string;
  value: string;
}>;

export function MachineForm(props: { form: FormInstance; data?: FieldType; machineCodeOptions: MachineCodeOptions }) {
  useEffect(() => {
    props.form.resetFields();
  }, [props.form]);

  return (
    <Form form={props.form} autoComplete="off" initialValues={props.data}>
      <Form.Item name="id" hidden></Form.Item>
      <Form.Item
        name="code"
        label="機器碼"
        rules={[{ required: true, message: "請選擇機器碼" }]}
        hidden={!isEmpty(props.data)}
      >
        <Select placeholder="請選擇機器碼" options={props.machineCodeOptions} />
      </Form.Item>
      <Form.Item name="mode" label="機器模式" rules={[{ required: true, message: "請選擇機器模式" }]}>
        <Select placeholder="請選擇機器模式" options={MACHINE_MODE_OPTIONS} />
      </Form.Item>
      <Form.Item name="name" label="名稱" rules={[{ required: true, message: "請輸入名稱" }]}>
        <Input />
      </Form.Item>
    </Form>
  );
}
