import { map } from "lodash";

export enum MACHINE_MODE {
  GRID = "GRID",
  AISLE = "AISLE",
}

export const MACHINE_MODE_LABEL = {
  [MACHINE_MODE.GRID]: "格子",
  [MACHINE_MODE.AISLE]: "貨道",
};

export const MACHINE_MODE_OPTIONS = map(MACHINE_MODE, (value, key) => ({
  label: MACHINE_MODE_LABEL[value],
  value,
}));
