import { useNavigate } from "react-router-dom";
import { Button, Image, Space, Table } from "antd";
import { useForm } from "antd/es/form/Form";
import { ColumnType } from "antd/es/table";
import useModal from "antd/es/modal/useModal";
import dayjs, { Dayjs } from "dayjs";
import { filter } from "lodash";
import numeral from "numeral";

import { FALLBACK_IMAGE_PATH } from "../../constants/material";

import { usePagination } from "../../hooks/usePagintation";
import { useCopyProductToOtherArea, useImagesList, useProductsList, useUpdateProduct } from "../../hooks/apis/vending";
import { useAreaStore } from "../../stores/useAreaStore";

import { ImageListForm } from "./ImageListForm";
import { InfoForm } from "./InfoForm";
import { AreaForm } from "./AreaForm";

type ProductItem = {
  productCode: string;
  productName: string;
  brandName: string;
  productType: string;
  productInfo: string;
  productMemo: string;
  price: number;
  expireDate: Dayjs | null;
  photo: string;
  index: number;
  src: string;
};

export function ProductList() {
  const navigate = useNavigate();
  const areaStore = useAreaStore();
  const pagination = usePagination({
    defaultPageSize: 30,
  });

  const { data: { products = [], total } = {}, isLoading: isProductsLoading } = useProductsList({
    skip: pagination.skip,
    take: pagination.take,
  });
  const { data: { images = [] } = {} } = useImagesList();
  const { mutateAsync: updateProduct } = useUpdateProduct();
  const { mutateAsync: copyProductToOtherArea } = useCopyProductToOtherArea();

  const [photoForm] = useForm<{
    productCode: string;
    photo: string;
  }>();
  const [infoForm] = useForm<ProductItem>();
  const [areaForm] = useForm<{
    targetAreaId: string;
  }>();

  const [modal, contextHolder] = useModal();

  const columns: ColumnType<ProductItem>[] = [
    {
      title: "照片",
      dataIndex: "photo",
      render: (value: string) => {
        const imageSrc = filter(
          images,
          (imageItem: { fileName: string; vendingFileName: string }) => imageItem.vendingFileName === value
        )?.[0];

        return (
          <Image
            width={100}
            src={`${process.env.REACT_APP_API_SERVER_URL}/vending/areas/${areaStore.selectedArea}/images/${imageSrc?.fileName}`}
            fallback={FALLBACK_IMAGE_PATH}
          />
        );
      },
    },
    {
      title: "名稱",
      render: record => (
        <>
          {record.brandName}
          <br />
          {record.productName}
        </>
      ),
    },
    {
      title: "類別",
      dataIndex: "productType",
    },
    {
      title: "簡述",
      dataIndex: "productInfo",
    },
    {
      title: "備註",
      dataIndex: "productMemo",
    },
    {
      title: "價錢",
      dataIndex: "price",
      render: (value: number) => numeral(value).format("$0,0"),
    },
    {
      title: "下架日",
      dataIndex: "expireDate",
      render: value => value && dayjs(value)?.format(`YYYY-MM-DD`),
    },
    {
      title: "操作",
      width: 100,
      render: record => (
        <Space>
          <Button
            size="small"
            onClick={() => {
              modal.confirm({
                cancelText: "取消",
                content: (
                  <ImageListForm
                    form={photoForm}
                    data={{ productCode: record.productCode, photo: record.photo }}
                    areaId={areaStore.selectedArea}
                  />
                ),
                icon: null,
                okText: "確認",
                title: "選擇要替換的圖片",
                onOk: async () => {
                  const values = await photoForm.validateFields();
                  return updateProduct({
                    ...values,
                    expireDate: undefined,
                  });
                },
              });
            }}
          >
            編輯圖片
          </Button>
          <Button
            size="small"
            onClick={() => {
              modal.confirm({
                cancelText: "取消",
                content: (
                  <InfoForm
                    form={infoForm}
                    data={{ ...record, expireDate: record.expireDate ? dayjs(record.expireDate) : null }}
                  />
                ),
                icon: null,
                okText: "確認",
                title: "更新資訊",
                onOk: async () => {
                  const values = await infoForm.validateFields();
                  return updateProduct({
                    ...values,
                    expireDate: values.expireDate ? dayjs(values.expireDate).valueOf() : null,
                    index: values.index ?? 1,
                  });
                },
              });
            }}
          >
            編輯資訊
          </Button>
        </Space>
      ),
    },
  ];

  const onCopyToOtherAreaClick = () => {
    modal.confirm({
      cancelText: "取消",
      content: <AreaForm form={areaForm} />,
      icon: null,
      okText: "確認",
      title: "從現在的場域進行複製商品",
      onOk: async () => {
        const values = await areaForm.validateFields();
        await copyProductToOtherArea(values);
      },
    });
  };

  return (
    <Space direction="vertical" className="w-full">
      <div className="text-2xl font-bold">商品管理</div>

      {contextHolder}

      <Table
        dataSource={products || []}
        columns={columns}
        loading={isProductsLoading}
        pagination={{ ...pagination, total }}
        scroll={{ x: "max-content" }}
        size="small"
        rowKey="productCode"
        title={() => (
          <Space direction="horizontal">
            <Button type="primary" onClick={() => navigate("/product/create")}>
              新增商品
            </Button>

            <Button onClick={onCopyToOtherAreaClick}>複製商品至其他場域</Button>
          </Space>
        )}
      ></Table>
    </Space>
  );
}
