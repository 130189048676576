import { Button, Col, Flex, Image, Row, Space, Table } from "antd";
import { ColumnType } from "antd/es/table";
import { map } from "lodash";
import dayjs from "dayjs";

import { FALLBACK_IMAGE_PATH } from "../../constants/material";
import { useMachineGridMenu, useMachineGridMenuDetail } from "../../hooks/apis/vending";

import { usePagination } from "../../hooks/usePagintation";
import { useNavigate } from "react-router-dom";

type MenuItem = {
  id: string;
  orderNumber: string;
  deadline: number;
};

type FieldType = {
  id: string;
  code: string;
  name: string;
};

export function RestockSettingGridForm(props: { data: FieldType; modal: any; areaId: string }) {
  const navigate = useNavigate();
  const pagination = usePagination({
    defaultPageSize: 30,
  });

  const { data: { menus = [], total } = {}, isLoading: isMachinesLoading } = useMachineGridMenu({
    machineId: props.data.id,
  });
  const { mutateAsync: getMachineGridMenuDetail } = useMachineGridMenuDetail();

  const columns: ColumnType<MenuItem>[] = [
    {
      title: "菜單號碼",
      dataIndex: "orderNumber",
    },
    {
      title: "截止日期",
      dataIndex: "deadline",
      render: value => value && dayjs(value)?.format(`YYYY-MM-DD HH:mm`),
    },
    {
      title: "操作",
      width: 100,
      render: (value, record) => (
        <Space>
          <Button
            size="small"
            onClick={async () => {
              const data =
                (
                  await getMachineGridMenuDetail({
                    machineId: props.data.id,
                    menuId: record.id,
                  })
                )?.data?.products || [];

              props.modal.info({
                content: (
                  <Row gutter={[16, 16]}>
                    {map(data, item => (
                      <Col span={8} key={item.productCode}>
                        <Flex vertical style={{ marginBottom: 10 }}>
                          <Image
                            width={100}
                            style={{ margin: "auto" }}
                            src={`${process.env.REACT_APP_API_SERVER_URL}/vending/areas/${props.areaId}/images/${item.fileName}`}
                            fallback={FALLBACK_IMAGE_PATH}
                          />

                          <div>{item.productName}</div>
                          <div>數量：{item.quantity}</div>
                        </Flex>
                      </Col>
                    ))}
                  </Row>
                ),
                icon: null,
                okText: "確認",
                title: "菜單詳情",
              });
            }}
          >
            查看
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Table
      dataSource={menus || []}
      columns={columns}
      loading={isMachinesLoading}
      pagination={{ ...pagination, total }}
      scroll={{ x: "max-content" }}
      size="small"
      rowKey="id"
      title={() => (
        <Space direction="horizontal">
          <Button type="primary" onClick={() => navigate(`/machine/menu-create?machineId=${props.data.id}`)}>
            新增菜單
          </Button>
        </Space>
      )}
    ></Table>
  );
}
