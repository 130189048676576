import { useEffect } from "react";
import { map } from "lodash";
import dayjs, { Dayjs } from "dayjs";
import { Button, Card, Col, DatePicker, Flex, Form, Image, Layout, Row } from "antd";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import { FormProps, useForm } from "antd/es/form/Form";
import Input from "antd/es/input/Input";

import { FALLBACK_IMAGE_PATH } from "../../constants/material";

import { useCreateMachineGridMenu, useProductsAllList } from "../../hooks/apis/vending";
import { useAreaStore } from "../../stores/useAreaStore";
import { useSearchParams } from "react-router-dom";

const initialValues = {
  quantity: 1,
};

type FieldType = {
  orderNumber: string | number;
  deadline: number | Dayjs;
  products: Array<{
    code: string;
    quantity: number;
  }>;
};

export function CreateGridMenu() {
  const [searchParams] = useSearchParams();
  const machineId = searchParams.get("machineId") ?? "";
  const areaStore = useAreaStore();
  const { data: { products = [] } = {} } = useProductsAllList();
  const { mutateAsync: createMachineGridMenu } = useCreateMachineGridMenu();

  const [form] = useForm<FieldType>();

  useEffect(() => {
    form.resetFields();
  }, [form]);

  useEffect(() => {
    if (areaStore.selectedArea) {
      form.resetFields(["products"]);
    }
  }, [areaStore.selectedArea, form]);

  const onFinish: FormProps<FieldType>["onFinish"] = async values => {
    if (values.products.length) {
      const data = {
        machineId,
        orderNumber: values.orderNumber,
        deadline: dayjs(values.deadline).valueOf(),
        products: map(values.products, item => ({
          code: item.code,
          quantity: item.quantity ?? 1,
        })),
      };
      await createMachineGridMenu(data);
      form.resetFields();
    }
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      autoComplete="off"
      initialValues={{ products: [] }}
      style={{ height: "calc(100% - 84px)" }}
    >
      <div className="text-2xl font-bold">新增菜單</div>

      <div className="flex flex-row justify-end mb-5 gap-2">
        <Button type="primary" htmlType="submit">
          儲存
        </Button>
      </div>

      <Form.Item
        name="orderNumber"
        label="菜單號碼"
        rules={[
          { required: true, message: "請輸入菜單號碼" },
          {
            validator: async (rule, value) => {
              if (value && !/^[0-9]+$/.test(value)) {
                throw new Error("只允許半型數字");
              }
            },
          },
        ]}
      >
        <Input style={{ maxWidth: 183 }} />
      </Form.Item>

      <Form.Item name="deadline" label="截止期限" rules={[{ required: true, message: "請選擇截止期限" }]}>
        <DatePicker placeholder="選擇截止期限" minDate={dayjs().startOf("day")} format="YYYY-MM-DD HH:mm" showTime />
      </Form.Item>

      <Form.List name="products">
        {(fields, { add, remove }, { errors }) => (
          <Layout hasSider style={{ maxHeight: "calc(100% - 100px)" }}>
            <Layout.Content style={{ overflowY: "auto" }}>
              <Flex gap={10} wrap="wrap">
                {map(
                  products,
                  (
                    item: {
                      fileName: string;
                      type: number;
                      vendingFileName: string;
                      productCode: string;
                      productName: string;
                    },
                    index
                  ) => (
                    <Flex vertical key={`${item.fileName}.${index}`} style={{ position: "relative", width: 100 }}>
                      <Image
                        width={100}
                        src={`${process.env.REACT_APP_API_SERVER_URL}/vending/areas/${areaStore.selectedArea}/images/${item.fileName}`}
                        fallback={FALLBACK_IMAGE_PATH}
                      />

                      <div className="mb-2">{item.productName}</div>

                      <Button
                        style={{ position: "absolute", top: 5, right: 5 }}
                        shape="circle"
                        icon={<PlusOutlined />}
                        onClick={() =>
                          add({
                            ...initialValues,
                            photo: item.vendingFileName,
                            src: item.fileName,
                            code: item.productCode,
                            productName: item.productName,
                          })
                        }
                      />
                    </Flex>
                  )
                )}
              </Flex>
            </Layout.Content>
            <Layout.Content style={{ overflowY: "auto" }}>
              <Row gutter={[16, 16]}>
                {fields.map((field, index) => {
                  const imageSrc = form.getFieldValue(["products", field.name, "src"]);
                  const productName = form.getFieldValue(["products", field.name, "productName"]);

                  return (
                    <Col span={12} key={field.key}>
                      <Card
                        title={`商品 - ${field.name + 1}`}
                        extra={
                          <CloseOutlined
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        }
                      >
                        <Flex justify="center" style={{ marginBottom: 10 }}>
                          <Image
                            width={100}
                            style={{ margin: "auto" }}
                            src={`${process.env.REACT_APP_API_SERVER_URL}/vending/areas/${areaStore.selectedArea}/images/${imageSrc}`}
                            fallback={FALLBACK_IMAGE_PATH}
                          />
                        </Flex>

                        <div className="mb-2">{productName}</div>

                        <Form.Item
                          name={[field.name, "quantity"]}
                          label="數量"
                          rules={[
                            { required: true, message: "請輸入數量" },
                            {
                              validator: async (rule, value) => {
                                if (!(value > 0)) {
                                  throw new Error(" 需大於1");
                                } else if (/^[0-9]+\.[0-9]+$/.test(value)) {
                                  throw new Error("不允許小數");
                                } else if (!/^\d{1,9}$/.test(value)) {
                                  throw new Error("位數需小於9位");
                                }
                              },
                            },
                          ]}
                          validateTrigger={["onChange", "onBlur"]}
                        >
                          <Input type="number" />
                        </Form.Item>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </Layout.Content>
          </Layout>
        )}
      </Form.List>
    </Form>
  );
}
