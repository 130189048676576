import { useMemo } from "react";
import { Button, Space, Table } from "antd";
import { useForm } from "antd/es/form/Form";
import { ColumnType } from "antd/es/table";
import useModal from "antd/es/modal/useModal";
import { map } from "lodash";

import { usePagination } from "../../hooks/usePagintation";
import { useMachineList, useMachineRemoteList, useUpdateMachine } from "../../hooks/apis/vending";

import { MachineForm } from "./MachineForm";
import { MACHINE_MODE, MACHINE_MODE_LABEL } from "../../constants/machine";

type RecordItem = {
  id: string;
  code: string;
  name: string;
  mode: MACHINE_MODE.GRID | MACHINE_MODE.AISLE;
};

export function MachineList() {
  const pagination = usePagination({
    defaultPageSize: 30,
  });

  const { data: { machines = [], total } = {}, isLoading: isMachinesLoading } = useMachineList({
    skip: pagination.skip,
    take: pagination.take,
  });

  const { data: { machines: machinesFromRemote = [] } = {}, isLoading: isMachinesRemoteLoading } =
    useMachineRemoteList();
  const { mutateAsync: updateMachine } = useUpdateMachine();

  const [form] = useForm<RecordItem>();

  const [modal, contextHolder] = useModal();

  const machineCodeOptions = useMemo(() => {
    return (
      map(machinesFromRemote, (item: { code: string; module1: string; name: string }) => ({
        label: `${item.module1} - ${item.name}`,
        value: item.code,
      })) || []
    );
  }, [machinesFromRemote]);

  const columns: ColumnType<RecordItem>[] = [
    {
      title: "名稱",
      dataIndex: "name",
    },
    {
      title: "機器模式",
      dataIndex: "mode",
      render: (record: RecordItem["mode"]) => <>{MACHINE_MODE_LABEL[record]}</>,
    },
    {
      title: "操作",
      width: 100,
      render: record => (
        <Space>
          <Button
            size="small"
            onClick={() => {
              modal.confirm({
                cancelText: "取消",
                content: <MachineForm form={form} data={record} machineCodeOptions={machineCodeOptions} />,
                icon: null,
                okText: "確認",
                title: "編輯機台",
                onOk: async () => {
                  const values = await form.validateFields();
                  return updateMachine(values);
                },
              });
            }}
          >
            編輯機台
          </Button>
        </Space>
      ),
    },
  ];

  const onCreateButtonClick = () => {
    modal.confirm({
      cancelText: "取消",
      content: <MachineForm form={form} machineCodeOptions={machineCodeOptions} />,
      icon: null,
      okText: "確認",
      title: "新增機台",
      onOk: async () => {
        const values = await form.validateFields();

        return updateMachine(values);
      },
    });
  };

  return (
    <Space direction="vertical" className="w-full">
      <div className="text-2xl font-bold">機台管理</div>

      {contextHolder}

      <Table
        dataSource={machines || []}
        columns={columns}
        loading={isMachinesLoading || isMachinesRemoteLoading}
        pagination={{ ...pagination, total }}
        scroll={{ x: "max-content" }}
        size="small"
        rowKey="id"
        title={() => (
          <Button type="primary" onClick={onCreateButtonClick}>
            新增機台
          </Button>
        )}
      ></Table>
    </Space>
  );
}
