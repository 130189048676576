import { Space, Tabs } from "antd";
import { useEffect, useState } from "react";
import useModal from "antd/es/modal/useModal";
import { map, reduce } from "lodash";

import { useMachineList, useProductsAllList, useUpdateMachineRestockSetting } from "../../hooks/apis/vending";
import { usePagination } from "../../hooks/usePagintation";

import { MACHINE_MODE } from "../../constants/machine";
import { useAreaStore } from "../../stores/useAreaStore";

import { RestockSettingGridForm } from "./RestockSettingGridForm";
import { RestockSettingAisleForm } from "./RestockSettingAisleForm";

type ProductItem = {
  productCode: string;
  productName: string;
  brandName: string;
  productType: string;
  productInfo: string;
  productMemo: string;
  price: number;
  expireDate: number | null;
  fileName: string;
  photo: string;
};

export function RestockSettingMachine() {
  const areaStore = useAreaStore();
  const pagination = usePagination({
    defaultPageSize: 30,
  });
  const { data: { machines = [] } = {} } = useMachineList({
    skip: pagination.skip,
    take: pagination.take,
  });
  const { data: { products = [] } = {} } = useProductsAllList();
  const { mutateAsync: updateRestockSetting } = useUpdateMachineRestockSetting();

  const [modal, contextHolder] = useModal();

  const [productsMapping, setProductsMapping] = useState<{
    [productCode: string]: ProductItem;
  }>({});

  useEffect(() => {
    if (products.length) {
      const mapping = reduce(
        products,
        (accumulate: { [productCode: string]: ProductItem }, item: ProductItem) => {
          accumulate[item.productCode] = item;
          return accumulate;
        },
        {}
      );

      setProductsMapping(mapping);
    }
  }, [products]);

  return (
    <Space direction="vertical" className="w-full">
      {contextHolder}

      <div className="text-2xl font-bold">機台庫存設置</div>

      <Tabs
        type="card"
        tabBarGutter={5}
        tabBarStyle={{ marginBottom: 0 }}
        destroyInactiveTabPane
        items={map(machines, item => ({
          label: item.name,
          key: item.id,
          children: (
            <>
              {item.mode === MACHINE_MODE.AISLE && (
                <RestockSettingAisleForm
                  key={item.id}
                  data={item}
                  modal={modal}
                  updateRestockSetting={updateRestockSetting}
                  productsMapping={productsMapping}
                  areaId={areaStore.selectedArea}
                />
              )}
              {item.mode === MACHINE_MODE.GRID && (
                <RestockSettingGridForm key={item.id} data={item} modal={modal} areaId={areaStore.selectedArea} />
              )}
            </>
          ),
        }))}
      />
    </Space>
  );
}
