import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { message } from "antd";
import { useAreaStore } from "../../stores/useAreaStore";
import { useAuthStore } from "../../stores/useAuthStore";
import { MACHINE_MODE } from "../../constants/machine";

export function useAreasList(data?: { skip?: number; take?: number }) {
  const authStore = useAuthStore();

  return useQuery(["areaList", data, authStore.token], async () => {
    if (!authStore.token) return;

    const response = await axios.get<{ areas: []; total: number }>("/vending/areas", { params: data });

    return response.data;
  });
}

export function useUpdateArea() {
  const queryClient = useQueryClient();

  return useMutation(
    ["area", "update"],
    (
      data: Array<{
        id?: string;
        name: string;
        companyId: string;
        token: string;
        supplierId: string;
      }>
    ) => {
      return axios.post("/vending/areas", { areas: data });
    },
    {
      onSuccess: () => {
        message.success("更新成功");
        queryClient.invalidateQueries("areaList");
      },
    }
  );
}

export function useImagesList() {
  const areaStore = useAreaStore();
  return useQuery(["imagesList", areaStore.selectedArea], async () => {
    if (!areaStore.selectedArea) return;

    const response = await axios.get<{ images: [] }>(`/vending/areas/${areaStore.selectedArea}/images`);
    return response.data;
  });
}

export function useUploadImage() {
  const areaStore = useAreaStore();
  const queryClient = useQueryClient();

  return useMutation(
    ["imagesList", "create"],
    (data: { types: number; images: any }) => {
      const fData = new FormData();
      data.images.forEach((element: File) => {
        if (element instanceof File) {
          fData.append("files", element);
          fData.append("types", String(data.types));
        }
      });

      return axios.post(`/vending/areas/${areaStore.selectedArea}/images`, fData);
    },
    {
      onSuccess: () => {
        message.success("新增成功");
        queryClient.invalidateQueries("imagesList");
      },
    }
  );
}

export function useProductsList(data: { skip?: number; take?: number }) {
  const areaStore = useAreaStore();
  return useQuery(["productList", data, areaStore.selectedArea], async () => {
    if (!areaStore.selectedArea) return;

    const response = await axios.get<{ products: []; total: number }>(
      `/vending/areas/${areaStore.selectedArea}/products`,
      { params: data }
    );

    return response.data;
  });
}

export function useProductsAllList(data?: { productCodes: any }) {
  const areaStore = useAreaStore();
  return useQuery(["productAllList", data, areaStore.selectedArea], async () => {
    if (!areaStore.selectedArea) return;

    if (data?.productCodes?.length === 0) {
      delete data.productCodes;
    }

    const response = await axios.get<{
      products: [];
    }>(`/vending/areas/${areaStore.selectedArea}/products/list`, {
      params: data,
    });

    return response.data;
  });
}

export function useCreateProduct() {
  const areaStore = useAreaStore();
  const queryClient = useQueryClient();

  return useMutation(
    ["product", "create"],
    (
      data: Array<{
        productName: string;
        brandName: string;
        productType: string;
        productInfo: string;
        productMemo: string;
        price: number;
        expireDate: number | null;
        photo: string;
        index: number;
        src: string;
      }>
    ) => {
      return axios.post(`/vending/areas/${areaStore.selectedArea}/products`, { products: data });
    },
    {
      onSuccess: () => {
        message.success("新增成功");
        queryClient.invalidateQueries("productList");
      },
    }
  );
}

export function useUpdateProduct() {
  const areaStore = useAreaStore();
  const queryClient = useQueryClient();

  return useMutation(
    ["product", "update"],
    (data: {
      productCode?: string;
      productName?: string;
      brandName?: string;
      productType?: string;
      productInfo?: string;
      productMemo?: string;
      price?: number;
      expireDate?: number | null;
      index?: number;
      photo?: string;
    }) => {
      return axios.patch(`/vending/areas/${areaStore.selectedArea}/products/${data.productCode}`, data);
    },
    {
      onSuccess: () => {
        message.success("更新成功");
        queryClient.invalidateQueries("productList");
      },
    }
  );
}

export function useMachineList(data: { skip?: number; take?: number }) {
  const areaStore = useAreaStore();
  return useQuery(["machinesList", data, areaStore.selectedArea], async () => {
    if (!areaStore.selectedArea) return;

    const response = await axios.get<{
      machines: { code: string; id: string; name: string; mode: MACHINE_MODE.GRID | MACHINE_MODE.AISLE }[];
      total: number;
    }>(`/vending/areas/${areaStore.selectedArea}/machines`, { params: data });
    return response.data;
  });
}

export function useUpdateMachine() {
  const areaStore = useAreaStore();
  const queryClient = useQueryClient();

  return useMutation(
    ["machine", "update"],
    (data: { id: string; code: string; name: string; mode: string }) => {
      return axios.post(`/vending/areas/${areaStore.selectedArea}/machines`, { machines: [data] });
    },
    {
      onSuccess: () => {
        message.success("更新成功");
        queryClient.invalidateQueries("machinesList");
      },
    }
  );
}

export function useMachineRemoteList() {
  const areaStore = useAreaStore();

  return useQuery(["machineRemoteList", areaStore.selectedArea], async () => {
    if (!areaStore.selectedArea) return;

    const response = await axios.get<{ machines: [] }>(`/vending/areas/${areaStore.selectedArea}/machines/remote-list`);

    return response.data;
  });
}

export function useMachineRestockSetting(data: { machineId: string }) {
  const areaStore = useAreaStore();

  return useQuery(["machineRestockSetting", data.machineId, areaStore.selectedArea], async () => {
    if (!areaStore.selectedArea) return;

    const response = await axios.get<{
      settings: Array<{
        layer: string;
        max: number;
        min: number;
        productCode: string;
        productId: number;
      }>;
    }>(`/vending/areas/${areaStore.selectedArea}/machines/${data.machineId}/restock-settings`);

    return response.data;
  });
}

export function useUpdateMachineRestockSetting() {
  const areaStore = useAreaStore();
  const queryClient = useQueryClient();

  return useMutation(
    ["machineRestockSetting", "update"],
    (data: { machineId: string; settings: {}[] }) => {
      return axios.post(`/vending/areas/${areaStore.selectedArea}/machines/${data.machineId}/restock-settings`, {
        settings: data.settings,
      });
    },
    {
      onSuccess: () => {
        message.success("更新成功");
        queryClient.invalidateQueries("machineRestockSetting");
      },
    }
  );
}

export function useCopyProductToOtherArea() {
  const areaStore = useAreaStore();

  return useMutation(
    ["copyProductToOtherArea", "create"],
    (data: { targetAreaId: string }) => {
      return axios.post(`/vending/areas/${areaStore.selectedArea}/transfer-products`, data);
    },
    {
      onSuccess: () => {
        message.success("複製成功");
      },
    }
  );
}

export function useMachineGridMenu(data: { machineId: string }) {
  const areaStore = useAreaStore();

  return useQuery(["machineGridMenu", data.machineId, areaStore.selectedArea], async () => {
    if (!areaStore.selectedArea) return;

    const response = await axios.get<{
      menus: Array<{
        id: string;
        orderNumber: string;
        deadline: number;
      }>;
      total: number;
    }>(`/vending/areas/${areaStore.selectedArea}/machines/${data.machineId}/menus`);

    return response.data;
  });
}

export function useCreateMachineGridMenu() {
  const areaStore = useAreaStore();
  const queryClient = useQueryClient();

  return useMutation(
    ["machineGridMenu", "update"],
    (data: {
      machineId: string;
      orderNumber: string | number;
      deadline: number;
      products: Array<{
        code: string;
        quantity: number;
      }>;
    }) => {
      return axios.post(`/vending/areas/${areaStore.selectedArea}/machines/${data.machineId}/menus`, data);
    },
    {
      onSuccess: () => {
        message.success("新增成功");
        queryClient.invalidateQueries("machineGridMenu");
      },
    }
  );
}

export function useMachineGridMenuDetail() {
  const areaStore = useAreaStore();

  return useMutation(["machineGridMenu", areaStore.selectedArea], (data: { machineId: string; menuId: string }) => {
    return axios.get(
      `/vending/areas/${areaStore.selectedArea}/machines/${data.machineId}/menus/${data.menuId}/products`
    );
  });
}
